.appHeader {
  background: var(--shade-7);
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 100;
}

.appHeader-logo {
  flex-shrink: 1;
}

.appHeader-logo svg {
  max-width: 100%;
}

.appHeader-primaryNav {
  padding: var(--outer-padding);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (min-width: 1240px) {
  .appHeader-primaryNav {
    padding-left: 0;
    padding-right: 0;
  }
}

.appHeader-primaryNav-left,
.appHeader-primaryNav-right {
  display: flex;
  align-items: center;
}

.appHeader-resources {
  display: flex;
}

.appHeader-resources a {
  margin-right: calc(var(--grid-unit) * 2);
  color: var(--shade-0);
  text-decoration: none;
}

.appHeader-resources a:hover {
  border-bottom: 1px solid var(--shade-0);
}

.appHeader-votingStatus {
  margin-right: calc(var(--grid-unit) * 2);
}

.votingStatus {
  align-items: center;
  display: flex;
}

.votingStatus a,
.appHeader-walletAddress {
  text-decoration: none;
}

.votingStatus a {
  color: var(--shade-4);
}

.votingStatus,
.appHeader-walletAddress {
  margin-right: calc(var(--grid-unit) * 2);
}

.votingStatus-icon {
  background: var(--shade-4);
  padding: 6px 4px;
  border-radius: 30px;
}

@media screen and (max-width: 1023px) {
  .appHeader-logo {
    margin-right: calc(var(--grid-unit) * 3);
  }
  .appHeader-resources,
  .votingStatus {
    display: none;
  }
  .appHeader-proposalsNav {
    display: none !important;
  } 
}

@media screen and (min-width: 1024px) {
  .appHeader-logo {
    margin-right: calc(var(--grid-unit) * 3);
  }
}