:root {

  /**
  * @tokens FontFamilies
  * @presenter FontFamily
  */

  --base-font-family: 'Iosevka Web', 'Courier New', monospace;

  /**
  * @tokens FontSizesMobile
  * @presenter FontSize
  */

  --font-xs: 10px;
  --font-s: 14px;
  --font-m: 16px;
  --font-l: 18px;
  --font-xl: 22px;
  --font-h2: 24px;
  --font-h1: 28px;

  /**
  * @tokens FontWeights
  * @presenter FontWeight
  */

  --font-light: 200;
  --font-normal: 400;
  --font-medium: 500;
  --font-bold: 700;

    /**
  * @tokens LineHeights
  * @presenter LineHeight
  */

  --line-height-tight: 1.25;
  --line-height-body: 1.4;

  /**
  * @tokens Spacing
  * @presenter Spacing
  */

  --grid-unit: 10px;
  --outer-padding: calc(var(--grid-unit) * 2);
}

/**
  * @tokens DesktopOverrides
  */

@media screen and (min-width: 1024px) {
  :root {
    --font-l: 20px;
    --font-xl: 26px;
    --font-h2: 28px;
    --font-h1: 48px;

    --outer-padding: calc(var(--grid-unit) * 3) calc(var(--grid-unit) * 4);
  }
}

/**
  * @tokens FullDesktopOverrides
  */

@media screen and (min-width: 1024px) {
  :root {
    --font-l: 26px;
    --font-xl: 30px;
    --font-h2: 36px;
    --font-h1: 52px;

    --outer-padding: calc(var(--grid-unit) * 3) calc(var(--grid-unit) * 4);
  }
}