.polls-filters {
  width: 100%;
  display: flex;
  justify-content: space-between;
  display: none; /* for now */
}

.polls-cards > * {
  margin-bottom: calc(var(--grid-unit) * 2); 
}

.polls-pagination {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 1024px) {
  .polls-cards-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: calc(var(--grid-unit) * 3);
    row-gap: calc(var(--grid-unit) * 3);
  }

  .polls-cards > * {
    margin-bottom: unset;
  }
}

.polls-footer {
  text-align: center;
}

.homePage .polls {
  border: 3px solid var(--shade-5);
  margin-bottom: calc(var(--grid-unit) * 5);
}