@import '../assets/styles/design-tokens.css';

.pageLayout {
  display: flex;
  justify-content: center;
  background: var(--shade-7);
  color: var(--shade-0);
  min-height: 100vh;
}

.pageLayout-contents {
  width: 100%;
  max-width: 1240px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pageLayout-body {
  padding: var(--outer-padding);
  padding-top: 0;
}

@media screen and (min-width: 1240px) {
  .pageLayout-contents {
    margin-left: 100px;
    margin-right: 100px;
  }
  .pageLayout-body {
    padding: unset;
    padding-top: unset;
  } 
}