.proposalDetail-header, 
.proposalDetail-adoptionStatus {
  padding: var(--outer-padding);
  background: var(--shade-6);
}

.proposalDetail-adoptionStatus {
  margin-top: calc(var(--grid-unit) * -3);
  background: var(--shade-5);
  display: flex;
  justify-content: center;
}
.proposalDetail-adoptionStatus * {
  margin-right: calc(var(--grid-unit) * 2);
}

.proposalDetail-meta {
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  color: var(--shade-2);
}

.proposalDetail-metaPrimary {
  display: flex;
  flex-direction: column;
}

.proposalDetail-url {
  color: var(--shade-2);
}

.proposalDetail-sidebarHeader {
  position: relative;
}

.proposalDetail-sidebarHeader-text {
  display: inline-block;
  background-color: var(--shade-7);
  position: relative;
  z-index: 1;
  padding-right: calc(var(--grid-unit) * 2);
}

.proposalDetail-sidebarHeader-line {
  display: block;
  position: absolute;
  z-index: 0;
  top: 50%; 
  left: 0;
  right: 0;
  height: 1px;
  background: var(--shade-5);
}

.proposalDetail-countdown {
  /* background: var(--shade-5);
  padding: 0 calc(var(--grid-unit) / 2); */
}

.proposalDetail-voteStatus {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.proposalDetail-yourVote {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  
  flex: 1 0 50%;
  justify-content: flex-end;
}
.proposalDetail-yourVote.multi-score {
  flex: 1 0 100%;
}
.proposalDetail-yourVote.multi-score > * {
  flex: 1;
}

.proposalDetail-yourVote svg {
  height: 1em;
}
.proposalDetail-yourVote svg path {
  stroke: white;
}

.proposalDetail-yourVote > * {
  margin: calc(var(--grid-unit) * 2);
  margin-right: 0;
  margin-bottom: var(--grid-unit);
  flex: 1 0 17%;
  max-width: 20%;
}

@media (max-width: 1000px) {
  .proposalDetail-yourVote > * {
    flex: 1 0 40%;
    max-width: 50%;
  }
}

.proposalDetail-yourVote button {
  height: auto;
  border: none;
  cursor: pointer;
  line-height: 2;
  padding-top: var(--grid-unit);
  padding-bottom: var(--grid-unit);
}

.proposalDetail-details {
  white-space: pre-wrap;
  flex: 1;
}

.proposalDetail-graph-labels {
  display: flex;
  justify-content: space-between;
}
.proposalDetail-graph-label {
  background: var(--shade-5);
  padding: 0.25em;
  margin-right: 0.25em;
}
.proposalDetail-graph-label:last-of-type {
  margin-right: 0;
}
.proposalDetail-graph-label.winner {
  background: black;
  color: white;
  font-weight: bold;
}

.proposalDetail-graph-bar {
  display: flex;
  margin: 0.5em 0;
}
.proposalDetail-graph-bar-part {
  flex: 1 0 auto;
  height: 2em;
}

@media screen and (min-width: 768px) {
  .proposalDetail-columns {
    display: flex;
    justify-content: space-between;
  }
  .proposalDetail-details {
    margin-right: calc(var(--grid-unit) * 3)
  }

  .proposalDetail-metaPrimary {
    flex-direction: row;
  }

  .proposalDetail-metaPrimary > * {
    margin-right: calc(var(--grid-unit) * 2);
  }

  .proposalDetail-voteStatus {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .proposalDetail-adoptionStatus {
    margin-top: calc(var(--grid-unit) * -5);
  }
}

@media screen and (min-width: 1240px) {
  .proposalDetail-header,
  .proposalDetail-adoptionStatus,
  .proposalDetail-columns {
    padding-left: calc(var(--grid-unit) * 10);
    padding-right: calc(var(--grid-unit) * 10);
  }

  .proposalDetail-header {
    padding-top: calc(var(--grid-unit) * 5);
    padding-bottom: calc(var(--grid-unit) * 5);
  }
}

/* .proposalDetail-yesNo {

}

.proposalDetail-multi {

} */
