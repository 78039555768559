.button {
  --button-height: calc(var(--grid-unit) * 4);
  display: inline-block;
  height: var(--button-height);
  padding-left: var(--button-height);
  padding-right: var(--button-height);
  color: var(--shade-7);
  background: var(--shade-0);
  border: none;
  font-family: var(--base-font-family);
  font-size: var(--font-m); 
  font-weight: var(--font-normal);
  text-transform: uppercase;
  cursor: pointer;
}

.button:not(.disabled):hover {
  background: var(--shade-2);
}

.button.disabled {
  background: var(--shade-4);
}

.button.voted {
  /* border: 5px solid var(--shade-4); */
  box-shadow: 0 0 0 3px white inset;
}