.text-xs        { font-size: var(--font-xs); font-weight: var(--font-normal); max-width: 80ch; }
.text-s         { font-size: var(--font-s); text-transform: uppercase; max-width: 80ch; }
.text-s-light   { font-size: var(--font-s); font-weight: var(--font-light); max-width: 80ch; }
.text-s-medium  { font-size: var(--font-s); font-weight: var(--font-medium); max-width: 80ch; }
.text-s-bold    { font-size: var(--font-s); font-weight: var(--font-bold); max-width: 80ch; }

p, .text-m      { font-size: var(--font-m); font-weight: var(--font-normal); line-height: var(--line-height-body); max-width: 80ch; }
.text-m-light   { font-size: var(--font-m); font-weight: var(--font-normal); max-width: 80ch; }
b,.text-m-medium  { font-size: var(--font-m); font-weight: var(--font-medium); max-width: 80ch; }

.text-button    { font-size: var(--font-m); font-weight: var(--font-normal); }

.text-l-light   { font-size: var(--font-l); font-weight: var(--font-light); text-transform: uppercase; max-width: 60ch; }
dt,.text-l-bold    { font-size: var(--font-l); font-weight: var(--font-bold); text-transform: uppercase; max-width: 60ch; }

.text-xl        { font-size: var(--font-xl); font-weight: var(--font-light); }

h1, .text-h1    { font-size: var(--font-h1); font-weight: var(--font-bold); text-transform: uppercase; }
h2, .text-h2    { font-size: var(--font-h2); font-weight: var(--font-bold); text-transform: uppercase; }

a               { color: var(--shade-0); font-weight: var(--font-normal); }
a:active,
a.active        { text-decoration: underline; }

h1, h2, h3, h4, h5, h6 {
  margin: var(--grid-unit) 0;
}